<script setup lang="ts">
import * as yup from 'yup';
import { useForm } from 'vee-validate';
import { userStore } from '~/stores/user';
import dayjs from 'dayjs';
import { useI18n } from 'vue-i18n';
import type { Area } from '~/types/area';
import type { User } from '~/types/user';
import type { Incident, IncidentType } from '~/types/incident';

// Props
const props = defineProps<{
  incident: Incident | null;
}>();

// Composables
const { $posthog } = useNuxtApp();
const { t } = useI18n();

// Store
const { showToast } = toastStore();

// Data
const modal = defineModel<boolean>();
const submiting = ref(false);

// Form
const schema = yup.object({
  start: yup.string().required(t('incident.form.start_date_is_required')),
  end: yup.string().required(t('incident.form.end_date_is_required')),
  name: yup.string(),
  area_id: yup.number().required(t('incident.form.area_is_required')),
  owner_id: yup.string().required(t('incident.form.owner_is_required')),
  type: yup.string().required(t('incident.form.type_is_required')),
  cost: yup.number().min(0, t('incident.form.must_be_a_number')).typeError(t('incident.form.must_be_a_number')),
});
const { handleSubmit, setValues, values, resetField } = useForm<Incident>({
  validationSchema: schema,
});

// Methods
const submit = handleSubmit(async (values) => {
  submiting.value = true;
  try {
    if (props.incident) {
      await incidentStore().updateIncident(props.incident.id, {
        ...values,
      });
      showToast({ type: 'success', title: t('global.success'), message: t('incident.form.incident_updated') });
      $posthog.capture('incident_updated', {
        start: values.start,
        end: values.end,
        name: values.name,
        area_id: values.area_id,
        owner_id: values.owner_id,
        type: values.type,
        cost: values.cost,
      });
    } else {
      await incidentStore().insertIncident({
        ...values,
        source_id: userStore().getCurrentUser.id,
        status: 'in_progress',
      });
      showToast({ type: 'success', title: t('global.success'), message: t('incident.form.incident_created') });
      $posthog.capture('incident_created', {
        start: values.start,
        end: values.end,
        name: values.name,
        area_id: values.area_id,
        owner_id: values.owner_id,
        type: values.type,
        cost: values.cost,
      });
    }

    modal.value = false;
  } catch (error) {
    showToast({ type: 'error', title: t('global.error'), message: t('global.message_error') });
    throw error;
  }
  submiting.value = false;
});

// Computed
const areaList = computed(() => {
  return areaStore().getAreas.map((area: Area) => {
    return {
      key: area.id,
      label: area.name,
    };
  });
});

const userList = computed(() => {
  return userStore().getUsers.map((user: User) => {
    return {
      key: user.id,
      label: `${user.first_name} ${user.last_name}`,
    };
  });
});

const typeList = computed(() => {
  return incidentStore().getIncidentsType.map((incident: IncidentType) => {
    return {
      key: incident.value,
      label: t(`incidents.type_${incident.value}`),
    };
  });
});

const disabledEndDates = computed(() => (values.start ? [{ end: dayjs(values.start).subtract(1, 'day').toDate() }] : []));

watch(
  () => values,
  () => {
    if (values.start && values.end && dayjs(values.start).isAfter(dayjs(values.end))) {
      resetField('end');
    }
  },
  {
    deep: true,
  },
);

watch(
  () => modal,
  () => {
    if (props.incident) {
      setValues({
        start: props.incident.start,
        end: props.incident.end,
        name: props.incident.name,
        area_id: props.incident.area_id,
        type: props.incident.type,
        cost: props.incident.cost,
        owner_id: props.incident.owner_id,
      });
    }
  },
  {
    deep: true,
  },
);
</script>
<template>
  <ui-modal v-model="modal" :title="incident ? $t('incidents.update_an_incident') : $t('incidents.create_an_incident')" width="500">
    <div class="grid grid-cols-2 gap-4">
      <ui-form-input-date name="start" type="dateTime" :label="$t('global.start_date')" required />
      <ui-form-input-date name="end" type="dateTime" :label="$t('global.end_date')" :disabled-dates="disabledEndDates" required />
    </div>
    <ui-form-input-text name="name" :label="$t('global.name')" :placeholder="$t('incident.incident_name')" required />

    <ui-form-input-select name="area_id" :label="$t('action_plan.area')" :items="areaList" required />

    <ui-form-input-select name="owner_id" :label="$t('incidents.owner')" :items="userList" required />

    <ui-form-input-select name="type" :label="$t('global.root_cause')" :items="typeList" required />

    <ui-form-input-text name="cost" type="number" placeholder="462" :label="$t('global.cost')" right-icon="Euro" />

    <template #footer>
      <ui-button @click="submit()">
        {{ incident ? $t('incident.update_incident') : $t('incident.create_incident') }}
      </ui-button>
    </template>
  </ui-modal>
</template>
